export function getUser() {
    const userData = window.localStorage.getItem('user')
    if (userData) {
        return JSON.parse(userData)
    }
    return null;
}

export function setUser(user) {
    const userJSON = JSON.stringify(user)
    window.localStorage.setItem('user', userJSON)
}
