import store from "@/state/store"

let token = window.localStorage.getItem("token")

export default [
  {
    path: "/",
    name: "default",
    meta: { authRequired: true },
    component: () => import("./views"),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (token) {
          next({ name: 'default' })
        } else {
          next()
        }
      },
    },
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("./views/account/login"),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Recuperar Senha",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        window.localStorage.clear()
        window.sessionStorage.clear()
        window.location.replace("/login")
        return [routeTo, routeFrom, next, store]
      },
    },
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations

  // Dashboard
  {
    path: '/dashboard/academico',
    name: 'dashboard.academico',
    meta: { authRequired: true },
    component: () => import('./views/dashboard/academico')
  },
  {
    path: '/dashboard/financeiro',
    name: 'dashboard.financeiro',
    meta: { authRequired: true },
    component: () => import('./views/dashboard/financeiro')
  },
  {
    path: '/dashboard/retencao',
    name: 'dashboard.retencao',
    meta: { authRequired: true },
    component: () => import('./views/dashboard/retencao')
  },

  // Aluno
  {
    path: '/aluno/buscar',
    name: 'aluno.buscar',
    meta: { authRequired: true },
    component: () => import('./views/aluno/buscar')
  },
  {
    path: '/aluno/dados-pessoais',
    name: 'aluno.dado.pessoal',
    meta: { authRequired: true },
    component: () => import('./views/aluno/dado-pessoal')
  },
  {
    path: '/aluno/registros-contato',
    name: 'aluno.registro.contato',
    meta: { authRequired: true },
    component: () => import('./views/aluno/registro-contato')
  },
  {
    path: '/aluno/analise-curricular',
    name: 'aluno.analise.curricular',
    meta: { authRequired: true },
    component: () => import('./views/aluno/analise-curricular')
  },
  {
    path: '/aluno/aproveitamento',
    name: 'aluno.aproveitamento',
    meta: { authRequired: true },
    component: () => import('./views/aluno/aproveitamento')
  },
  {
    path: '/aluno/atividades',
    name: 'aluno.atividade',
    meta: { authRequired: true },
    component: () => import('./views/aluno/atividade')
  },
  {
    path: '/aluno/documentos-entregues',
    name: 'aluno.documento.entregue',
    meta: { authRequired: true },
    component: () => import('./views/aluno/documento-entregue')
  },
  {
    path: '/aluno/financeiro',
    name: 'aluno.financeiro',
    meta: { authRequired: true },
    component: () => import('./views/aluno/financeiro')
  },
  {
    path: '/aluno/matriculas',
    name: 'aluno.matricula',
    meta: { authRequired: true },
    component: () => import('./views/aluno/matricula')
  },
  {
    path: '/aluno/notas',
    name: 'aluno.nota',
    meta: { authRequired: true },
    component: () => import('./views/aluno/nota')
  },


  // Gestão Acadêmica
  {
    path: '/gestao-academica/documentos-nao-entregues',
    name: 'gestao.academica.documento.nao.entregue',
    meta: { authRequired: true },
    component: () => import('./views/gestao-academica/documento-nao-entregue')
  },
  {
    path: '/gestao-academica/alunos',
    name: 'gestao.academica.aluno',
    meta: { authRequired: true },
    component: () => import('./views/gestao-academica/aluno')
  },
  {
    path: '/gestao-academica/dependencias',
    name: 'gestao.academica.dependencia',
    meta: { authRequired: true },
    component: () => import('./views/gestao-academica/dependencia')
  },
  {
    path: '/gestao-academica/aniversariantes',
    name: 'gestao.academica.aniversariante',
    meta: { authRequired: true },
    component: () => import('./views/gestao-academica/aniversariante')
  },



  // Retenção
  {
    path: '/retencao/acesso-ava',
    name: 'retencao.acesso.ava',
    meta: { authRequired: true },
    component: () => import('./views/retencao/acesso-ava')
  },
  {
    path: '/retencao/atividades',
    name: 'retencao.atividade',
    meta: { authRequired: true },
    component: () => import('./views/retencao/atividade')
  },
  {
    path: '/retencao/solicitacoes-evasao',
    name: 'retencao.solicitacao.evasao',
    meta: { authRequired: true },
    component: () => import('./views/retencao/solicitacao-evasao')
  },


  // FINANCEIRO
  // {
  //   path: '/financeiro/parcelas',
  //   name: 'financeiro.parcela',
  //   meta: { authRequired: true },
  //   component: () => import('./views/financeiro/parcela')
  // },
  {
    path: '/financeiro/parcelas-gerais',
    name: 'financeiro.parcela.geral',
    meta: { authRequired: true },
    component: () => import('./views/financeiro/parcela-geral')
  },
  {
    path: '/financeiro/parcelas-aberto',
    name: 'financeiro.parcela.aberto',
    meta: { authRequired: true },
    component: () => import('./views/financeiro/parcela-aberto')
  },


    // Captação
    {
      path: '/captacao/inscricoes',
      name: 'captacao.inscricao',
      meta: { authRequired: true },
      component: () => import('./views/captacao/inscricao')
    },


  // Links Úteis
  {
    path: '/links-uteis',
    name: 'link.util',
    meta: { authRequired: true },
    component: () => import('./views/link-util')
  },

  // Admin
  {
    path: '/admin/usuarios',
    name: 'admin.usuario',
    meta: { authRequired: true },
    component: () => import('./views/admin/usuario')
  },
  {
    path: '/admin/links-uteis',
    name: 'admin.link.util',
    meta: { authRequired: true },
    component: () => import('./views/admin/link-util')
  },

  // Master
  {
    path: '/master/usuarios',
    name: 'master.usuario',
    meta: { authRequired: true },
    component: () => import('./views/master/usuario')
  },
  {
    path: '/master/polos',
    name: 'master.polo',
    meta: { authRequired: true },
    component: () => import('./views/master/polo')
  },
  {
    path: '/master/links-uteis',
    name: 'master.link.util',
    meta: { authRequired: true },
    component: () => import('./views/master/link-util')
  },
  {
    path: '/master/logs-acesso',
    name: 'master.log.acesso',
    meta: { authRequired: true },
    component: () => import('./views/master/log-acesso')
  },
  // {
  //   path: "*",
  //   redirect: "404",
  // },
];