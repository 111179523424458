import Swal from "sweetalert2"

export default function axiosCatch(error) {
    if ('response' in error) {
        if ('status' in error.response) {
            switch (error.response.status) {
                case 401:
                    window.location.href = "/logout";
                    break

                case 403:
                    Swal.fire({
                        title: "<strong>Ocorreu um erro</strong>",
                        icon: "warning",
                        html: '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">Permissão negada.</div>',
                    })
                    break

                case 404:
                    Swal.fire({
                        title: "<strong>Ocorreu um erro</strong>",
                        icon: "warning",
                        html: '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">Não encontrado.</div>',
                    })
                    break

                case 405:
                    Swal.fire({
                        title: "<strong>Ocorreu um erro</strong>",
                        icon: "warning",
                        html: '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">' + error.response.data.message + '</div>',
                    })
                    break

                case 422:
                    if (typeof error.response.data.errors === 'object' && error.response.data.errors !== null) {
                        let html = "<ul>"
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            html = html + "<li>" + value[0] + "</li>"
                            key
                        }
                        html = html + "</ul>"
                        Swal.fire({
                            title: "<strong>Ocorreu um erro</strong>",
                            icon: "warning",
                            html: '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">' + html + '</div>',
                        })
                    }
                    else {
                        Swal.fire({
                            title: "<strong>Ocorreu um erro</strong>",
                            icon: "warning",
                            html: '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">' + error.response.data.message + '</div>',
                        })
                    }
                    break

                case 500:
                    Swal.fire({
                        title: "<strong>" + error.response.data.error + "</strong>",
                        icon: "warning",
                        html: '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">' + error.response.data.message + '</div>',
                    })
                    break

                default:
                    Swal.fire("Ocorreu um erro.", error.response.statusText, "warning")
                    break
            }
        }
    }
}